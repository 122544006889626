import React, { useEffect, useState } from 'react';

import { FaRegHandshake, FaRegComment } from 'react-icons/fa';
import { BiMessage } from 'react-icons/bi';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';





const HeroPostPage = ({ searchResultsPosts }) => {
  const {id} = useParams(); // for grabing ID from the URL

  // Sample data for cards
  const win = window.localStorage; 
  const navigate = useNavigate();


  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(0); // Current page number (used as offset)
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // To check if more posts are available
  const [grabState, setGrabState] = useState(true); // To check if more posts are available
  const [currentUrl, setCurrentUrl] = useState("");

  // const [offset_, setOffset] = useState(0); // To check if more posts are available

  useEffect(()=>{
    // const baseUrl = window.location.origin; // Gets the base URL (e.g., https://www.ngomongdong.com)
    const currentUrl = window.location.href;
    setCurrentUrl(currentUrl);
    // console.log(searchResultsPosts)



    if (currentUrl.includes("usr")) {
      getUserById();
      setPosts([]); // Clear the posts
      setGrabState(false);
      // console.log("andjenejnejnej");

    } 
    // 4. SAVE THE DATA TO POSTS use "setPosts"
    else if (currentUrl.includes("search")){
      setPosts(searchResultsPosts)

    }
    else {
      // console.log(grabState);
      if(grabState){
        getPosts();
        // console.log("once");


      }
      setGrabState(false);
      // getPosts();



    }
  }, [searchResultsPosts,id,currentUrl]); // Include `id` to trigger a re-fetch when the URL changes
  // const getPosts = async() =>{
  //   const response = await axios.get("https://api.ngomongdong.composts");
  //   setPosts(response.data);
  //   console.log(response.data)
  // }
  // Function to fetch posts
  const getPosts = async () => {
    // console.log("aaa");
    if (loading || !hasMore) return; // Prevent multiple requests if already loading or no more posts

    setLoading(true);
    try {
      const limit = 10;
      const offset = page * limit; // Calculate the offset
      
      // https://api.ngomongdong.com DEFAULT URL !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
      
      const response = await axios.get(`https://api.ngomongdong.com/posts?limit=${limit}&offset=${offset}`);
      
      // console.log("Fetched data:", response.data); // Debugging: Check if the API returns duplicates
      // setOffset(offset_+10);
      // console.log("Offset calculated:", offset);


      // Avoid setting duplicate posts
      const newPosts = response.data.filter(post => !posts.some(existingPost => existingPost.post_id_encr === post.post_id_encr));
      // console.log("Filtered new posts:", newPosts); // Debugging: Check if filtering works
      // console.log("offset:", offset); // Debugging: Check if filtering works
      // console.log("page:", page); // Debugging: Check if filtering works
      // console.log("Prev  posts:", posts); // Debugging: Check if filtering works
      // console.log("ke fetch gk nambah page");

      
      if (newPosts.length > 0 && grabState) {
        setPosts(prevPosts => [...newPosts]); // Append only new posts
        setPage(1); // Increment page after successful fetch
        // console.log("final all posts:", posts.length); // Debugging: Check if filtering works

        // console.log("pertama ex", page);

      }

      else if ((newPosts.length > 0) && !grabState) {
        // Append new posts first
        setPosts(prevPosts => {
          // Combine old posts and new posts
          const updatedPosts = [...prevPosts, ...newPosts];
      
          // Create a Set to track unique post IDs
          const uniquePostIds = new Set();
          
          // Filter to keep only unique posts
          const deduplicatedPosts = updatedPosts.filter(post => {
            if (uniquePostIds.has(post.post_id_encr)) {
              return false; // Duplicate, don't include
            } else {
              uniquePostIds.add(post.post_id_encr); // Add to Set
              return true; // Unique, include it
            }
          });
      
          return deduplicatedPosts; // Return deduplicated posts
        });
      
        setPage(prevPage => prevPage + 1); // Increment page after successful fetch
        // console.log("Total posts after deduplication:", posts.length); // Debugging
      }
      
      else {
        setHasMore(false); // No more posts available
      }
      setGrabState(false);

      // if (response.data.length > 0) {
      //   setPosts(prevPosts => [...prevPosts, ...response.data]); // Append new posts
      //   // setPage(page + 1); // Increment page count
      //   setPage(prevPage => prevPage + 1); // Increment page after successful fetch
      // } else {
      //     setHasMore(false); // If no more posts, stop further loading
      // }
      
    } catch (error) {
      // console.error('Error fetching posts:', error);
    } finally {
      setLoading(false);
    }
  };

  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => func(...args), delay);
    };
  };

  // Infinite scroll handler
  
  // const handleScroll = () => {
  //   // if ((window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 200) && (!currentUrl.includes("usr")) && (!loading)) {
  //   if ((window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 400) && !loading && hasMore  && (!currentUrl.includes("usr"))  && (!currentUrl.includes("search"))) {
  //     // !currentUrl.includes("usr") agar getPosts tidak terpanggil dan double dan loading terus
  //     getPosts(); // Load more posts when reaching the bottom
  //     console.log("sampe bawah");

  //   }
  // };

  let scrollTimeout;
  const handleScroll = () => {
    if (scrollTimeout) clearTimeout(scrollTimeout);

    scrollTimeout = setTimeout(() => {
      if ((window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 400) && !loading && hasMore && (!currentUrl.includes("usr"))  && (!currentUrl.includes("search"))) {
        // console.log("ke scroll get post"); // Debugging: Check if filtering works
        // console.log(currentUrl); // Debugging: Check if filtering works
        if (!window.location.href.includes("usr")){
          getPosts();
        }
      }
    }, 200); // 200ms delay to debounce
  };

  // Add scroll event listener
  useEffect(() => {
    // window.addEventListener('scroll', handleScroll);
    // return () => {
    //   window.removeEventListener('scroll', handleScroll); // Clean up the listener on component unmount
    // };
    const debouncedHandleScroll = debounce(handleScroll, 1500);
    window.addEventListener('scroll', debouncedHandleScroll);
    return () => window.removeEventListener('scroll', debouncedHandleScroll);
    
  }, [page,currentUrl]);
  const getUserById = async () =>{
    /// BLM SEPERTI getPosts yaa !! blm bisa scroll load data baru bla2, masih normal biasa
    try {
        setPosts([]); // Clear the posts
        const response = await axios.get(`https://api.ngomongdong.com/user/${id}`);
        // console.log(response.data.posts)
        // so it fetches user data and the posts of the user
        setPosts(response.data.posts);

    } 
    
    catch (error) {
        if (error.response.status === 404) {
          // console.log(error.response.data.msg);
        }
        

    }
  };
  // const getPossts = async() =>{
  //   const response = await axios.get("https://api.ngomongdong.composts");
  //   setPosts(response.data);
  //   // console.log(response.data.length)
  // }



  

  

  // const exploreData = [
  //   {
  //     id: 1,
  //     image: 'https://example.com/image1.jpg',
  //     title: 'Explore Card 1',
  //   },
  //   {
  //     id: 2,
  //     image: 'https://example.com/image2.jpg',
  //     title: 'Explore Card 2',
  //   },
  //   {
  //       id: 2,
  //       image: 'https://example.com/image2.jpg',
  //       title: 'Explore Card 2',
  //   },
  //   {
  //       id: 1,
  //       image: 'https://example.com/image1.jpg',
  //       title: 'Explore Card 1',
  //     },
  //     {
  //       id: 2,
  //       image: 'https://example.com/image2.jpg',
  //       title: 'Explore Card 2',
  //     },
  //     {
  //         id: 2,
  //         image: 'https://example.com/image2.jpg',
  //         title: 'Explore Card 2',
  //     },
  //     {
  //       id: 1,
  //       image: 'https://example.com/image1.jpg',
  //       title: 'Explore Card 1',
  //     },
  //     {
  //       id: 2,
  //       image: 'https://example.com/image2.jpg',
  //       title: 'Explore Card 2',
  //     },
  //     {
  //         id: 2,
  //         image: 'https://example.com/image2.jpg',
  //         title: 'Explore Card 2',
  //     },
  //     {
  //       id: 1,
  //       image: 'https://example.com/image1.jpg',
  //       title: 'Explore Card 1',
  //     },
  //     {
  //       id: 2,
  //       image: 'https://example.com/image2.jpg',
  //       title: 'Explore Card 2',
  //     },
  //     {
  //         id: 2,
  //         image: 'https://example.com/image2.jpg',
  //         title: 'Explore Card 2',
  //     },
    
  //   // Add more data as needed
  // ];

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 mb-10">
      <h1 className="text-3xl font-bold my-6">.</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {posts.map((card) => (
          <Link to={`/currentpost/${card.post_id_encr}`}>
          <div key={card.post_id_encr} className={`rounded-lg shadow-md pb-8 break-words ${card.color}`}>
            <p className='w-full h-64 object-cover rounded-t-lg text-3xl ml-1 mt-1 font-hand text-black'>{card.title}</p>
            {/* <img src={card.image} alt={card.title} className="w-full h-64 object-cover rounded-t-lg" /> */}
            <div className="p-2 break-words">
              <div className='flex flex-row space-x-4 items-center'>
                <div class="w-12 h-12">
                  <img class="rounded-full shadow-sm object-cover w-12 h-12" src={card.user.url}alt="user image" />
                </div>
                <h2 className="text-xl font-semibold mb-2">{card.userUsername}</h2>
              </div>
                <p className="text-gray-900 mt-3 mb-3">
                  {card.content.length > 30 ? `${card.content.substring(0, 50)}.....` : card.content}
                </p>
            </div>
            
            <div className='flex flex row justify-end mr-2 mb-2 mt-1'>
              {/* <button
              type="submit"
              className="text-gray-200 w-[90px] hover:bg-[#0c0c0c] font-hand rounded-lg font-lg my-0.5 mx-4 md:mx-0 py-3 bg-black"
              >
              Can Relate
              </button> */}
              <p className='  '>
                <FaRegHandshake size={28}></FaRegHandshake>
              </p>
              <div id="tooltip-dark" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                Tooltip content
              <div class="tooltip-arrow" data-popper-arrow></div>
              </div>
              <p className='ml-2.5 text-lg'>{card.relate_point_count}</p>
              <p className=' ml-5  '>
                <FaRegComment size={28}></FaRegComment>
              </p>
              <div id="tooltip-dark" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                Tooltip content
              <div class="tooltip-arrow" data-popper-arrow></div>
              </div>
              <p className='ml-2.5 text-lg'>{card.comment_count}</p>
              
              
            </div>
          </div>
          </Link>
        ))}
        {loading && <p className='text-gray-500'>Tunggu sebentar yaa...</p>}
        {!hasMore && <p className='text-gray-500'>Anda sudah mencapai batas akhir curhatan.</p>}
      </div>
    </div>
    
  );
};

export default HeroPostPage;
