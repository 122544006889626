import React, { useEffect, useState } from 'react';
import {AiOutlineBell, AiOutlinePlus, AiOutlineEye, AiOutlineSetting, AiOutlineClose, AiOutlineEdit, AiOutlineMenu, AiOutlineUser, AiOutlineUserAdd, AiOutlineLogout,AiOutlineLogin, AiOutlineKey} from 'react-icons/ai'
import { FaRegHandshake } from 'react-icons/fa';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';



const NavbarUser = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownTagsOpen, setIsDropdownTagsOpen] = useState(false);
  const [isNotifModalOpen, setNotifModalOpen] = useState(false); // To manage modal visibility
  const [isMenuOpen, setIsMenuOpen] = useState(false); // manage menu on mobile mode
  // const [isDropdownNotifOpen, setIsDropdownNotifOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [relate_points, setRelatePoints] = useState(0);
  const [url, setUrl] = useState('');

  const [notifications, setNotifications] = useState([]); // for storing notifications
  const [notifCount, setNotifCount] = useState(); // For storing the count of notifications

  const [searchQuery, setSearchQuery] = useState(""); // storing searchbar
  const navigate = useNavigate();

  // check if there are user that are logged in
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [usernameLog, setUsernameLog] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');


  const win = window.localStorage; 


  // set the "profile to name"
  useEffect(()=>{
    if (win.getItem('username') != null){
      setUsername(win.getItem('username'));
      getUserById(win.getItem('username'));
      setIsUserLoggedIn(true);
      getNotif();
      
    }
    else{
      setUsername("Profile");
    }
  },[url])

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleDropdownToggle = () => {
    setIsDropdownTagsOpen(!isDropdownTagsOpen);
  };

  // const toggleDropdownNotif = () => {
  //   setIsDropdownNotifOpen(!isDropdownNotifOpen);
  // };

  const toggleNotifModal = () => {
    setNotifModalOpen(!isNotifModalOpen);
  };
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const getNotif = async() =>{
    // for user validation
    const formData = new FormData();
    formData.append("usernameTang", win.getItem('username')); // 'Tanggaper' is current user login info
    formData.append("pass", win.getItem('password'));

    // console.log(idpostreal+"aaaa");

    try {
        const resp_ = axios.post("https://api.ngomongdong.com/notifications", formData, {
            headers:{
                "Content-Type": "multipart/form-data"
            }
        });
        // navigate("");
        // console.log((await resp_).data)
        setNotifications((await resp_).data);
        setNotifCount((await resp_).data.length);

        
    } catch (error) {
        // console.log(error.response.status);
        if (error.response.status == 400){
            // console.log(error.response + "error bangsat");
        }

        
    }

    
  }

  /// mark as read
  const markAsRead = async (notifId) => {

    const formData = new FormData();
    formData.append("notifid", notifId); 

    // for user validation
    formData.append("usernameTang", win.getItem('username')); // 'Tanggaper' is current user login info
    formData.append("pass", win.getItem('password'));

     // Update the state to remove the notification from the list
    setNotifications((prevNotifications) => 
      prevNotifications.filter((notif) => notif.notification_id !== notifId)
    );
    // also update notification count
    setNotifCount(notifCount-1);


    try {
      await axios.post("https://api.ngomongdong.com/markasread", formData, {
          headers:{
              "Content-Type": "multipart/form-data"
          }
      });
      // navigate("");
      // getCommentsByPostId();
    }
    catch (error) {
      // console.error('Error marking notification as read:', error);
    }
      
   
    
    
  };
  // for getting users url and relate points

  const getUserById = async (username) =>{
    try {
        const response = await axios.get(`https://api.ngomongdong.com/user/${username}`);
        setUrl(await response.data.user.url);
        setRelatePoints(await response.data.user.relate_points);
        // const user_ = response.data.user;
        // setUrl(user_.url);
        
        // console.log("triggered_")

    } 
    
    catch (error) {
        if (error.response.status === 404) {
          // console.log(error.response.data.msg);
        }
        

    }
  };

  // Handle input change
  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Handle key down (Enter key)
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      // Navigate to the search page if input is not empty
      if (searchQuery.trim()) {
        navigate(`/search?query=${encodeURIComponent(searchQuery)}`);
      }
    }
  };
  const getUser = async(e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("username", usernameLog); // nama "username" sesuai di backend
    formData.append("pass", password);

    try {
        const response = await axios.post("https://api.ngomongdong.com/userpost", formData, {
            headers:{
                "Content-Type": "multipart/form-data"
            }
        });
        // store to local storage if login success
        // console.log(response.data.user.password)
        win.setItem('username', response.data.user.username);
        win.setItem('password', response.data.user.password);
        navigate("/", {replace: true});
        
    } catch (error) {
        // console.log(error.response.status);
        if (error.response.status == 400){
            setLoginError("true");
        }

        
    }

  }

  const handleNavigationToPostFromNotif = (id) => {
    // Navigate to `/` first
    navigate('/');
    

    // After a short delay, navigate to the specific post
    setTimeout(() => {
      navigate(`/currentpost/${id}`);
    }, 10); // 500ms delay, adjust as needed
  };



  return (
    <nav className="bg-[#161616] sticky z-20 top-0">
      <div className="max-w-7xl mx-auto px-1 sm:px-5 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="flex-shrink-0 ml-0.5 md:ml-0">
              <h1 className="w-full text-1xl md:text-3xl mr-4 font-hand text-gray-100">
                NgomongDong.com
              </h1>
            </Link>
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                <div className="relative">
                  {/* "Tags" link that toggles the dropdown */}
                  <a
                    href={isUserLoggedIn ? "#" : null}
                    className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium mr-2"
                    onClick={isUserLoggedIn ? handleDropdownToggle : (e) => e.preventDefault()} // Disable click if not logged in
                  >
                    Tags
                  </a>

                  {/* Dropdown menu */}
                  {isDropdownTagsOpen && (
                    <ul className="absolute left-0 mt-2 w-48 bg-[#161616] text-gray-300 rounded-md shadow-lg z-20">
                    <li className="px-4 py-2 hover:bg-gray-700">
                      <Link
                        to={`/searchtags?query=Hidup`}
                        className="text-left w-full block"
                        onClick={() => setIsDropdownTagsOpen(false)}
                      >
                        Hidup
                      </Link>
                    </li>
                    <li className="px-4 py-2 hover:bg-gray-700">
                      <Link
                        to={`/searchtags?query=Hubungan`}
                        className="text-left w-full block"
                        onClick={() => setIsDropdownTagsOpen(false)}
                      >
                        Hubungan
                      </Link>
                    </li>
                    <li className="px-4 py-2 hover:bg-gray-700">
                      <Link
                        to={`/searchtags?query=Keluarga`}
                        className="text-left w-full block"
                        onClick={() => setIsDropdownTagsOpen(false)}
                      >
                        Keluarga
                      </Link>
                    </li>
                    <li className="px-4 py-2 hover:bg-gray-700">
                      <Link
                        to={`/searchtags?query=Lingkungan`}
                        className="text-left w-full block"
                        onClick={() => setIsDropdownTagsOpen(false)}
                      >
                        Lingkungan
                      </Link>
                    </li>
                    <li className="px-4 py-2 hover:bg-gray-700">
                      <Link
                        to={`/searchtags?query=Others`}
                        className="text-left w-full block"
                        onClick={() => setIsDropdownTagsOpen(false)}
                      >
                        Others
                      </Link>
                    </li>
                  </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
  
          <div className="flex items-center justify-center flex-grow">
            <div className="w-full px-2 py-1 flex items-center justify-center bg-[#2d2d2d] rounded-md">
              <input
                type="text"
                placeholder="Search"
                className="bg-transparent text-gray-200 outline-none placeholder-gray-400 w-full"
                disabled={!isUserLoggedIn} 
                value={searchQuery}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown} // Listen for Enter key press
              />
              {/* <svg
                className="w-4 h-4 text-gray-400 ml-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 19l-6-6m0 0l-6-6m6 6l-6 6m6-6l6 6M5 11l7-7 7 7M5 11v8a2 2 0 002 2h2m8-10a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m0 0h6m-6 0v6m0-6l6 6"
                ></path>
              </svg> */}
            </div>
          </div>
          <div className="hidden md:block">
            
            <div className="ml-4 flex items-center md:ml-6">
              <div className="relative flex flex-row">
                {isUserLoggedIn ? (
                  <Link
                    to={`/createpost`}
                    className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-1 rounded-sm text-sm font-medium"
                    title="Buat curhatan"
                  >
                    <h1 className="text-xl mt-5">
                      <AiOutlinePlus size={18} />
                    </h1>
                  </Link>
                ) : (
                  <div
                    className="text-gray-300 px-3 py-1 rounded-sm text-sm font-medium"
                  >
                    <h1 className="text-xl" style={{ marginTop: '0.361rem' }}>
                      <AiOutlinePlus size={18} />
                    </h1>
                  </div>
                )}
                <button
                    onClick={toggleNotifModal}
                    type="button"
                    className="relative text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-sm text-sm font-medium"
                    disabled={!isUserLoggedIn} 
                >
                    <h1 className='text-xl mb-0.5'><AiOutlineBell size={20}></AiOutlineBell></h1>
                    {notifCount > 0 && (
                        <span className="absolute top-2.5 right-1 inline-flex items-center justify-center px-1 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                            {notifCount}
                        </span>
                    )}
                </button>
                {isUserLoggedIn && (
                <button
                  onClick={toggleDropdown}
                  type="button"
                  className="flex flex-col items-center text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-1 -mr-3 ml-2 mt-1 rounded-md text-sm font-medium"
                >
                  {/* Profile image */}
                  <div className="flex items-center">
                    <img
                      src={url} // Add your image URL here
                      alt="Profile"
                      className="w-8 h-8 rounded-full mr-2" // Circular image
                    />
                    <h2 className="">{username}</h2>
                  </div>

                  {/* Likes count below the username */}
                  <div className='flex flex-row mt-1'>
                    <p className="text-xs text-gray-400">{relate_points} relate points</p>
                    <FaRegHandshake className='w-3 h-5 text-def-green ml-2 '></FaRegHandshake>

                  </div>
                </button>
                )}
                {/* if user is not logged in */}
                {isUserLoggedIn === false && (
                <button
                onClick={() => setIsLoginModalOpen(true)} // Trigger login modal
                type="button"
                className="text-def-black bg-[#00df9a] hover:bg-[#146d51] hover:text-def-black px-4 py-2 flex flex-row justify-items-center rounded-md text-sm font-medium ml-2"
              >
                {/* [#00df9a] */}
                <AiOutlineLogin className='mr-2 mt-0.5' size={18}></AiOutlineLogin>Masuk
              </button>
                )}
                
                {isDropdownOpen && (
                  <div className="origin-top-right absolute right-0 mt-11 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <div
                      className="py-1"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="user-menu"
                    >
                      <Link
                        to={`/usr/${username}`}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                        onClick={() => setIsDropdownOpen(false)}
                      >
                        Profile ku
                      </Link>
                      <Link
                        to="/usersettings"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                        onClick={() => setIsDropdownOpen(false)}
                      >
                      
                        Pengaturan
                      </Link>
                      <button
                        onClick={() => {
                          // Clear the username and password from localStorage or sessionStorage
                          win.removeItem('username');
                          win.removeItem('password');

                          // Navigate to the explore page
                          window.location.href = "/";
                        }}
                        className="block px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                        role="menuitem"
                      >
                        Logout
                      </button>
                      {/* Add more dropdown menu items as needed */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* NOTIFICATION MODAL */}
          {isNotifModalOpen && (

            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50 ">
              <div className="bg-white text-black rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full p-5">
                <h2 className="text-xl font-bold mb-4">Notifikasi</h2>
                <div className="max-h-96 overflow-y-auto">  {/* Set max height and enable scrolling */}
                  <ul>
                    {notifications.map((notif, index) => (
                      <div
                        key={index} // add a key to the list items
                        // to={`/currentpost/${notif.post_id_encr}`}
                        className="block m-3 px-4 py-3 text-sm text-black hover:bg-gray-100"
                        role="menuitem"
                        onClick={() => handleNavigationToPostFromNotif(notif.post_id_encr)}>
                        {notif.notification_cond === "com" && (
                          <p className='flex flex-row items-center'>
                            <img class="mt-2 rounded-full w-8 h-8 sm:w-10 sm:h-10" src={notif.tanggaper.url} alt=""></img>
                            {/* <h3 className='ml-3'>{notif.tanggaper.username} has commented on your post</h3> */}
                            <h3 className='ml-3'>{notif.tanggaper.username} telah berkomentar di curhatan anda</h3>
                            <div className="relative group ml-auto">
                              <button
                                type="button"
                                className="text-gray-700 hover:text-gray-800 hover:bg-gray-200 hover:rounded-xl p-1"
                                onClick={(e) => {
                                  e.preventDefault(); // Prevent the default link action
                                  e.stopPropagation(); // Stop the event from bubbling up to the parent div
                                  markAsRead(notif.notification_id); // Call the function to mark as read
                                }}
                              >
                                <AiOutlineEye size={22} />
                              </button>
                              <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block bg-white text-gray-700 text-xs rounded py-1 px-2">
                                Tandai
                              </span>
                            </div>
                          </p>
                        )}

                        {notif.notification_cond === "rep" && (
                          <p className='flex flex-row items-center'>
                            <img class="mt-2 rounded-full w-8 h-8 sm:w-10 sm:h-10" src={notif.tanggaper.url} alt=""></img>
                            {/* <h3 className='ml-3'>{notif.tanggaper.username} has replied to your comment</h3> */}
                            <h3 className='ml-3'>{notif.tanggaper.username} telah membalas komentar anda</h3>
                            <div className="relative group ml-auto">
                              <button
                                type="button"
                                className="text-gray-700 hover:text-gray-800 hover:bg-gray-200 hover:rounded-xl p-1"
                                onClick={(e) => {
                                  e.preventDefault(); // Prevent the default link action
                                  markAsRead(notif.notification_id); // Call the function to mark as read
                                }}
                              >
                                <AiOutlineEye size={22} />
                              </button>
                              <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block bg-white text-gray-700 text-xs rounded py-1 px-2">
                                Tandai
                              </span>
                            </div>
                          </p>
                        )}

                        {notif.notification_cond === "rel" && (
                          <p className='flex flex-row items-center'>
                            <img class="mt-2 rounded-full w-8 h-8 sm:w-10 sm:h-10" src={notif.tanggaper.url} alt=""></img>
                            {/* <h3 className='ml-3'>{notif.tanggaper.username} relates to your post</h3> */}
                            <h3 className='ml-3'>{notif.tanggaper.username} relate dengan curhatan anda</h3>
                            <div className="relative group ml-auto">
                              <button
                                type="button"
                                className="text-gray-700 hover:text-gray-800 hover:bg-gray-200 hover:rounded-xl p-1"
                                onClick={(e) => {
                                  e.preventDefault(); // Prevent the default link action
                                  markAsRead(notif.notification_id); // Call the function to mark as read
                                }}
                              >
                                <AiOutlineEye size={22} />
                              </button>
                              <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block bg-white text-gray-700 text-xs rounded py-1 px-2">
                                Tandai
                              </span>
                            </div>
                          </p>
                        )}
                      </div>
                    ))}
                  </ul>
                </div>
                <button
                  onClick={toggleNotifModal}
                  className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md"
                >
                  Close
                </button>
              </div>
            </div>
          )}

          {/* Login Modal */}
          {isLoginModalOpen && (

            <div className="fixed inset-0 bg-black bg-opacity-75 flex flex-col justify-center items-center z-50">
              <h1 className='text-5xl mb-10 font-hand text-gray-100'>NgomongDong.com</h1>
              <div className="bg-[#000300] p-5 md:p-20 rounded-md shadow-xl">
                <h2 className="text-center mb-20 text-lg text-[#00df9a] border-b border-gray-800">
                  YUK LOGIN DULU !
                </h2>
                <form onSubmit={getUser} className="flex flex-col mb-10">
                  <div className='flex flex-row mr-10 mb-2'>
                    <AiOutlineUser size={20} className='text-white mt-3 mr-5'></AiOutlineUser>
                    <input
                      type="text"
                      placeholder="Username"
                      value={usernameLog}
                      onChange={(e) => setUsernameLog(e.target.value)}
                      required
                      className="px-4 py-2 mb-3 border border-gray-300 rounded"
                    />
                  </div>
                  <div className='flex flex-row mb-5'>
                    <AiOutlineKey size={20} className='text-white mt-3 mr-5'></AiOutlineKey>
                    <input
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      className="px-4 py-2 border border-gray-300 rounded"
                    />
                  </div>
                  {loginError === "true" && (
                    <div className='mb-8 ml-11 mt-[-2]'>
                      <p className="text-red-500 text-xs">Username or password is wrong</p>
                    </div>
                  )}
                  <button type="submit" className="px-4 my-6 mx-auto py-3 bg-[#00df9a] text-black w-[200px] rounded-md font-medium">
                    Log In
                  </button>
                </form>

                {/* Add the "Don't have an account? Register" link here */}
                <p className="text-center text-sm mb-5 text-white">
                  Belum pernah daftar? <Link to="/register" className="text-[#00df9a] hover:underline">Daftar</Link>
                </p>
              </div>
            </div>
          )}


          {/* Mobile menu button */}
          <div className="md:hidden">
            <button
              type="button"
              className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
              aria-label="Open mobile menu"
              onClick={toggleMenu}
            >
              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {/* Mobile menu */}
      
      <div className={`fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500 ${isMenuOpen ? 'left-0' : 'left-[-100%]'}`}>
      
        {/* Profile image */}
        {isUserLoggedIn ? ( 
        <div className='w-full text-2xl md:text-5xl text-gray-300 m-4'>
        {/* flex flex-col items-center text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-1 -mr-3 ml-2 mt-1 rounded-md text-sm font-medium */}
          <div className="flex items-center">
            <img
              src={url} // Add your image URL here
              alt="Profile"
              className="w-8 h-8 rounded-full mr-2" // Circular image
            />
            <h2 className="">{username}</h2>
          </div>

          {/* Likes count below the username */}
          <div className='flex flex-row mt-1'>
            <p className="text-xs text-gray-400">{relate_points} relate points</p>
            <FaRegHandshake className='w-3 h-5 text-def-green ml-2 '></FaRegHandshake>

          </div>
        </div>
        ) : (
        <h1 className='w-full text-3xl md:text-5xl font-hand text-gray-100 m-4'>NgomongDong.com</h1>
        )}

    {isUserLoggedIn ? ( 
    <ul>
          {/* <Link to={`login`}> */}
          <Link to={`/usr/${username}`} onClick={() => setIsMenuOpen(false)}>
            <div className='flex flex-row'>
              <li className='pl-5 pt-5 text-white'>
                <AiOutlineUser size={20} />
              </li>
              <li className='p-4 text-white'>
                Profil ku
              </li>
            </div>
          </Link>
          {/* </Link> */}
          {/* <Link to={`register`}> */}
            <div className='border-b border-gray-600'/>
            <button 
            onClick={() => {
              toggleNotifModal();
              // setIsMenuOpen(false); // Close menu on button click
            }}
            type="button"
            className='flex flex-row '>
              <li className='pl-5 pt-5  text-white '><AiOutlineBell size={20}></AiOutlineBell></li>
              {notifCount > 0 && (
                      <div className='absolute'>
                        <span className="absolute mt-2 ml-8 inline-flex items-center justify-center px-1 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                            {notifCount}
                        </span>
                      </div>
                    )}
              <li className='p-4 text-white'>Notifikasi</li>
            </button>
            <div className='border-b border-gray-600'/>

          
          {/* </Link> */}
          <Link to={`/createpost`} onClick={() => setIsMenuOpen(false)}>
          <div className='flex flex-row '>
            <li className='pl-5 pt-5 text-white'><AiOutlinePlus size={20}></AiOutlinePlus></li>
            <li className='p-4 text-white'>Tambah curhatan</li>
          </div>
          </Link>
          <div className='border-b border-gray-600'/>
          <Link to={`/usersettings`} onClick={() => setIsMenuOpen(false)}>
          <div className='flex flex-row '>
            <li className='pl-5 pt-5 text-white'><AiOutlineSetting size={20}></AiOutlineSetting></li>
            <li className='p-4 text-white'>Pengaturan</li>
          </div>
          </Link>
          <button 
          onClick={() => {
            // Clear the username and password from localStorage or sessionStorage
            win.removeItem('username');
            win.removeItem('password');

            // Navigate to the explore page
            window.location.href = "/";
          }}
          type="button"
          // 
          className='flex flex-row'>
            <li className='pl-5 pt-5 text-red-600'><AiOutlineLogout size={20}></AiOutlineLogout></li>
            <li className='p-4 text-red-600 ml-0.5'>Logout</li>
          </button>
        

          
    </ul>
    ) : (
      <ul className='mt-10'>
            <div className='border-b border-gray-600'/>
            <button 
            onClick={() => setIsLoginModalOpen(true)}
            type="button"
            className='flex flex-row'>
              <li className='pl-5 pt-5 text-def-green 'style={{ marginTop: '0.070rem'}}><AiOutlineLogin size={20}></AiOutlineLogin></li>
              <li className='p-4 text-def-green text-lg'>Masuk</li>
            </button>
            <div className='border-b border-gray-600'/>
    </ul>

    )}
</div>
    </nav>
  );
};

export default NavbarUser;
