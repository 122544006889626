import React, { useEffect, useState } from 'react';
import {AiOutlineClose, AiOutlineEdit, AiOutlineLogin, AiOutlineMenu, AiOutlineUser, AiOutlineUserAdd} from 'react-icons/ai'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';



const Navbar = () => {
    const[nav, setNav] = useState(false)
    // local stor var
    const win = window.localStorage;
    const navigate = useNavigate();


    const handleNav = () => {
        setNav(!nav)
    }

    // redirect to explore page if user stil logged in ( local storage )
    useEffect(()=>{
      if (win.getItem('username') && win.getItem('password')){
        navigate("/explore");

      }
    },[])

  return (
    <div className='flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white border-b border-gray-900 '>
        <h1 className='w-full text-5xl font-hand text-gray-100'>NgomongDong.com</h1>
        <ul className='text-[#00df9a] hidden md:flex'>
            <Link to={`login`}>
                <div className='flex flex-row'>
                <li className='pl-5 pt-5 '><AiOutlineLogin size={20}></AiOutlineLogin></li>
                <li className='p-4'>Masuk</li>
                </div>
            </Link>
            <Link to={`register`}>
                <div>
                <li className='p-4'>Daftar</li>
                </div>
            </Link>
            {/* <li className='p-4'>Resources</li>
            <li className='p-4'>About</li>
            <li className='p-4'>Contact</li> */}
        </ul>
        <div onClick={handleNav} className='md:hidden'>
            {nav ? <AiOutlineClose size={20}/> : <AiOutlineMenu size={20}/> }
        </div>
        <div className={nav ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500' : 'fixed left-[-100%]'}>
        <h1 className='w-full text-3xl md:text-5xl font-hand text-gray-100 m-4'>NgomongDong.com</h1>

            <ul>
                {/* <li className='p-4 border-b border-gray-600'>Login</li> */}
                <Link to={`login`}>
                    <div className='flex flex-row'>
                    <li className='pl-5 pt-5 '><AiOutlineLogin size={20}></AiOutlineLogin></li>
                    <li className='p-4'>Masuk</li>
                    </div>
                </Link>
                {/* <li className='p-4 border-b border-gray-600'>Register</li> */}
                <Link to={`register`}>
                    <div className='flex flex-row border-b border-gray-600'>
                    <li className='pl-5 pt-5 '><AiOutlineEdit size={20}></AiOutlineEdit></li>
                    <li className='p-4 '>Daftar</li>
                    </div>
                </Link>
                <li className='p-4 text-gray-600 text-xs'>Developed and created by Daffa Hafizh</li>
                </ul>
        </div>
    </div>
  )
}

export default Navbar