import React from 'react';
import Typed from 'react-typed';
import { Link } from 'react-router-dom';
import AdComponent from './AdComponent';


const Hero = () => {
  return (
    <div className='text-white'>
        {/* <div className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'> */}
        <div className='max-w-[800px] mt-[96px] w-full h-screen mx-auto grid md:grid-cols-3'>
          <div className='flex flex-col md:col-span-2 ml-4'>
            <p className='text-[#00df9a] font-bold md:p-2'>PLATFORM BAGI SEMUA ORANG UNTUK</p>
              <h1 className='md:text-6xl sm:text-6xl text-4xl font-bold md:py-6'>Berkeluh kesah tentang yang mengganjal.</h1>
              <div className='flex flex-col'>
                  <p className='md:text-2xl sm:text-4xl text-xl font-bold'>Kalian punya masalah ? Dan pengen</p>
                  <Typed className='md:text-5xl sm:text-4xl text-xl font-extralight font-hand text-[#00df9a]' strings={['Curhat ?','Sambat ?','Ngeluh ?']} typeSpeed={120} backSpeed={140} loop/>
              </div>
          </div>
          <div className='md:my-20 md:ml-50 justify-center text-center md:text-start '>
            <p className='md:text-2xl text-xl font-bold text-gray-600'>Tenang kok, Ada kami di sini. untuk menampung beban-beban kalian <span className='text-white font-hand'>: )</span></p>
            <Link to={`explore`}>
            <button className='bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-black'>
              Ayok Lihat Curhatan!
              </button>
            </Link>
          </div>
        </div>
        {/* Insert Ad Component below post content */}
        <div className="my-1">
          <AdComponent /> {/* Displays the AdSense ad here */}
        </div>
    </div>
    
  )
}

export default Hero