import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { FaRegHandshake } from 'react-icons/fa';



const UserPage = () => {
  const {id} = useParams(); // for grabing ID from the URL
  const [userData, setUserData] = useState({
    username: '',
    firstName: '',
    lastName: '',
    profilePicture: '',
    relatePoints: 0,
    createdAt: ''
  });

  

  const [loading, setLoading] = useState(true);

  const win = window.localStorage;
  const navigate = useNavigate();

  useEffect(() => {
    checkUserLoggedIn();
    getUserById();
    
  }, [id]);
  const checkUserLoggedIn = () => {
    if (localStorage.getItem('username') != null) {
      
        
    } else {
        navigate("/login");

    }
};

// }, [userData]); // DISABLED DUE TO EXTREME REFRESH

//   const fetchUserData = async () => {
//     const formData = new FormData();
//     formData.append("usernameTang", win.getItem('username'));

//     try {
//       const response = await axios.post("https://api.ngomongdong.com/getuserdata", formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });

//       const user = response.data.user;
//       setUserData({
//         firstName: user.first_name,
//         lastName: user.last_name,
//         profilePicture: user.url,
//       });
//     } catch (error) {
//       console.error("Error fetching user data", error);
//     }
//   };

  const getUserById = async () =>{
    setLoading(true);
    try {
        const response = await axios.get(`https://api.ngomongdong.com/user/${id}`);
  
        const user_ = response.data.user;
        setUserData({
            username: user_.username,
            firstName: user_.first_name,
            lastName: user_.last_name,
            profilePicture: user_.url,
            relatePoints: user_.relate_points,
            createdAt: user_.createdAt
        });
        // console.log(user_)
        // window.location.reload();
        // getUserById();

    } 
    
    catch (error) {
        if (error.response.status === 404) {
          // console.log(error.response.data.msg);
        }
        

    }
    finally {
      setLoading(false); // Stop loading after fetching or error
    }
  };
  
  // if (loading) {
  //   return <p className="text-center text-gray-500">Loading data user...</p>;
  // }
  // window.location.reload();

  return (
    <div>
      {loading ? <p className="text-center text-gray-500">Loading data user...</p> : ""}
      
    <div className="max-w-full mx-4 mt-5">
      {/* Banner */}
      <div className="relative w-full bg-black h-56 object-cover rounded-t-md">
        {/* Profile Picture */}
        <div className="absolute bottom-0 left-5 transform translate-y-1/2">
          <img
            src={userData.profilePicture || 'default-profile-pic-url.jpg'}
            alt="Profile"
            className="rounded-full w-32 h-32 border-4 border-white"
          />
        </div>
      </div>

      {/* User Info */}
      <div className="bg-zinc-950 shadow-md rounded-md p-5">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-2xl font-bold text-slate-50 mt-14">
              {userData.firstName} {userData.lastName}
            </h1>
            <p className="text-gray-600">@{userData.username}</p>
          </div>
          {/* <button className="bg-blue-500 text-white px-4 py-2 rounded-md">
            Follow
          </button> */}
        </div>

        {/* <p className="mt-4 text-gray-800"> Bergabung saat {new Date(userData.createdAt).toLocaleDateString('id-ID', { day: '2-digit', month: 'long', year: 'numeric' })}</p> */}

        <p className="mt-4 text-gray-800">
          Bergabung saat {loading ? "" : new Date(userData.createdAt).toLocaleDateString('id-ID', { day: '2-digit', month: 'long', year: 'numeric' })}
        </p>

        {/* Likes Count */}
        <div className="mt-4 flex items-center">
          {/* <svg className="w-6 h-6 text-red-500 mr-2" fill="currentColor" viewBox="0 0 24 24">
            <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
          </svg> */}
            <FaRegHandshake size={28} className='w-6 h-6 text-def-green  mr-2'></FaRegHandshake>
          <span className="text-gray-600 font-semibold">{userData.relatePoints} Relate Points</span>
        </div>
      </div>
    </div>
    </div>
  );
};
export default UserPage;
