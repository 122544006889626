import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FaRegHandshake } from 'react-icons/fa';
import { BiMessage, BiDotsVerticalRounded } from 'react-icons/bi';
import {AiOutlineClose, AiOutlineKey, AiOutlineMenu, AiOutlineUser} from 'react-icons/ai'
// import AdComponent from './AdComponent';


const CurrentPost = () => {
  const [isDropdownReplyOpen, setIsDropdownReplyOpen] = useState(false);
  const [isDropdownRepliesOpen, setIsDropdownRepliesOpen] = useState(false);
  const[username_post, setUsernamePost] = useState("");
  const[time_post, setTimePost] = useState("");
  // time to display how old is the post
  const [timeAgo, setTimeAgo] = useState("");
  const[idpostreal, setIdPostReal] = useState("0");
  const[title, setTitle] = useState("");
  const [tags, setTags] = useState([]);
  const [comments, setComments] = useState([]);
  const [replies, setReplies] = useState([]);
  const[content, setContent] = useState("");
  const[relate_point, setRelatePoint] = useState("0");
  const[comment, setCommentCount] = useState("0");
  const [Commentcontent, setCommentContent] = useState('');
  const [Replytcontent, setReplytContent] = useState('');
  const [isRelateClicked, setIsRelateClicked] = useState(false); // added to track if the button is clicked
  // get delete button to be available
  const [isDeleteAvailable, setisDeleteAvailable] = useState(false);
  // for delete button
  const [isKebabMenuOpen, setIsKebabMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // delete success button
  const [isModalMessageOpen, setIsModalMessageOpen] = useState(false);
  // check if there are user that are logged in
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  // when post is loading
  const [loading, setLoading] = useState(false);
  const [loadingSaveCom, setLoadingSaveCom] = useState(false);
  const [loadingSaveRep, setLoadingSaveRep] = useState(false);








  const {id} = useParams(); // for grabing ID from the URL
  const navigate = useNavigate();
  const win = window.localStorage; 

  useEffect(()=>{
    getPostById();
    getTagsByPostId();
    getCommentsByPostId();
    checkUserLoggedIn();
    
    


    
    
  },[]);

  const checkUserLoggedIn = () => {
    if (localStorage.getItem('username') != null) {
      setIsUserLoggedIn(true);
    } else {
      setIsLoginModalOpen(true);
    }
  };

  const toggleKebabMenu = () => setIsKebabMenuOpen(!isKebabMenuOpen);
  const confirmDelete = () => {
    setIsKebabMenuOpen(false);
    setIsModalOpen(true);
  };
  const deletePost = async(e) => {
    // Add logic to delete the post
      e.preventDefault();
      const formData = new FormData();
      formData.append("postid", idpostreal);
      // for user validation
      formData.append("username", win.getItem('username'));
      formData.append("pass", win.getItem('password'));
  
      try {
          await axios.post("https://api.ngomongdong.com/deletepost", formData, {
              headers:{
                  "Content-Type": "multipart/form-data"
              }
          });
          setIsModalOpen(false);
          setIsModalMessageOpen(true);
          // 5 seconds sleep
          setTimeout(() => {
            navigate("/explore");
            window.location.reload();
          }, 5000); // 5000 milliseconds = 5 seconds
  
          
      } catch (error) {
          // console.log(error.response.status);
          if (error.response.status == 400){
              // console.log(error.response + "error bangsat");
          }
  
          
      }



    ////////////
    
  };

  const toggleDropdownReply = (id) => {
    // setIsDropdownReplyOpen(!isDropdownReplyOpen);
    setIsDropdownReplyOpen({
      // ...isDropdownReplyOpen,
      [id]: !isDropdownReplyOpen[id],
    });
    setReplytContent(""); // for when clicking other reply button set it to blank

  };
  // https://api.ngomongdong.com

    const toggleDropdownReplies = (id) => {
      // setIsDropdownReplyOpen(!isDropdownReplyOpen);
      setIsDropdownRepliesOpen({
        ...isDropdownRepliesOpen,
        [id]: !isDropdownRepliesOpen[id],
      });
      getRepliesByCommentId(id);


    };

  const getPostById = async () =>{
    setLoading(true);
    try {
    const response = await axios.get(`https://api.ngomongdong.com/posts/${id}`);
    setUsernamePost(response.data.userUsername);
    setTimePost(response.data.createdAt);
    setTitle(response.data.title);
    setContent(response.data.content);
    setRelatePoint(response.data.relate_point_count);
    setCommentCount(response.data.comment_count);
    setIdPostReal(response.data.post_id);
    // console.log("kita kyk gini:"+relate_point);

    // get relate condition
    getRelateCond(response.data.post_id);
    
    // console.log(title+""+file+""+preview);

    // function for displaying how old is the post
    const calculateTimeAgo = () => {
      const postTime = new Date(response.data.createdAt); // Parse the time_post string to a Date object
      const currentTime = new Date(); // Get the current time

      const timeDifference = currentTime - postTime; // Get the difference in milliseconds

      // Convert the difference into seconds, minutes, hours, days, months, years
      const seconds = Math.floor(timeDifference / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const months = Math.floor(days / 30);
      const years = Math.floor(months / 12);

      if (years > 0) {
        return years === 1 ? "1 tahun lalu" : `${years} tahun lalu`;
      } else if (months > 0) {
        return months === 1 ? "1 bulan lalu" : `${months} bulan lalu`;
      } else if (days > 0) {
        return days === 1 ? "1 hari lalu" : `${days} hari lalu`;
      } else if (hours > 0) {
        return hours === 1 ? "1 jam lalu" : `${hours} jam lalu`;
      } else if (minutes > 0) {
        return minutes === 1 ? "1 menit lalu" : `${minutes} menit lalu`;
      } else {
        return seconds === 1 ? "1 detik lalu" : `${seconds} detik lalu`;
      }
    };
    
    // Call the function and set the result to the state
    setTimeAgo(calculateTimeAgo());
    }
    catch (error) {
      //     console.error("Error fetching post data:", error);
    }
    finally {
          setLoading(false); // Stop loading after fetching or error
    }

    /////////////


  };

  // const getPostById = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await axios.get(`https://api.ngomongdong.com/posts/${id}`);
  //     setUsernamePost(response.data.userUsername);
  //     setTimePost(response.data.createdAt);
  //     setTitle(response.data.title);
  //     setContent(response.data.content);
  //     setRelatePoint(response.data.relate_point_count);
  //     setCommentCount(response.data.comment_count);
  //     // get relate condition
  //     getRelateCond(response.data.post_id);
      
  //     // Calculate "time ago" for the post
  //     const calculateTimeAgo = () => {
  //       const postTime = new Date(response.data.createdAt);
  //       const currentTime = new Date();
  //       const timeDifference = currentTime - postTime;

  //       const seconds = Math.floor(timeDifference / 1000);
  //       const minutes = Math.floor(seconds / 60);
  //       const hours = Math.floor(minutes / 60);
  //       const days = Math.floor(hours / 24);
  //       const months = Math.floor(days / 30);
  //       const years = Math.floor(months / 12);

  //       if (years > 0) return years === 1 ? "1 tahun lalu" : `${years} tahun lalu`;
  //       if (months > 0) return months === 1 ? "1 bulan lalu" : `${months} bulan lalu`;
  //       if (days > 0) return days === 1 ? "1 hari lalu" : `${days} hari lalu`;
  //       if (hours > 0) return hours === 1 ? "1 jam lalu" : `${hours} jam lalu`;
  //       if (minutes > 0) return minutes === 1 ? "1 menit lalu" : `${minutes} menit lalu`;
  //       return seconds === 1 ? "1 detik lalu" : `${seconds} detik lalu`;
  //     };

  //     setTimeAgo(calculateTimeAgo());
  //   } catch (error) {
  //     console.error("Error fetching post data:", error);
  //   } finally {
  //     setLoading(false); // Stop loading after fetching or error
  //   }
  // };

  

  const getTagsByPostId = async () =>{
    const response = await axios.get(`https://api.ngomongdong.com/tags/${id}`);
    setTags(response.data);
    

    // console.log(title+""+file+""+preview);


  };

  const getCommentsByPostId = async () =>{
    const response = await axios.get(`https://api.ngomongdong.com/comments/${id}`);
    setComments(response.data);
    

    // console.log(title+""+file+""+preview);
    // console.log(response.data);


  };
  // const getRepliesByCommentId = async (id_com) =>{
  //   const response = await axios.get(`https://api.ngomongdong.com/replies/${id_com}`);
  //   setReplies(response.data);
  //   // setReplies({
  //   //   ...replies,
  //   //   [id_com]: response.data
  //   // });
  //   // console.log(replies);
    

  //   // console.log(title+""+file+""+preview);


  // };
  const getRepliesByCommentId = async (id_com) => {
    const response = await axios.get(`https://api.ngomongdong.com/replies/${id_com}`);
    // Assuming response.data is an array of replies
    setReplies((prevReplies) => ({
      ...prevReplies,
      [id_com]: response.data,
    }));
  };

  const saveComment = async(e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("content", Commentcontent); 
    formData.append("postidencry", id); 
    formData.append("postid", idpostreal);
    formData.append("usernameCur", username_post); // 'Curhater' is the current user's username from the current posts
    // for user validation
    formData.append("usernameTang", win.getItem('username')); // 'Tanggaper' is current user login info
    formData.append("pass", win.getItem('password'));
    setLoadingSaveCom(true);
    try {
        await axios.post("https://api.ngomongdong.com/commentpost", formData, {
            headers:{
                "Content-Type": "multipart/form-data"
            }
        });
        // navigate("");
        getCommentsByPostId();
        // empty text area
        setCommentContent("");

        
    } catch (error) {
        // console.log(error.response.status);
        if (error.response.status == 400){
            // console.log(error.response + "error bangsat");
        }

        
    }
    finally {
      setLoadingSaveCom(false); // Stop loading after fetching or error
    }

  

  


  }

  const saveRelatePoint = async () => {
    const formData = new FormData();
    formData.append("postidencry", id); 
    formData.append("postid", idpostreal);
    formData.append("usernameCur", username_post); // current post's user
    formData.append("usernameTang", win.getItem('username')); // current logged-in user
    formData.append("pass", win.getItem('password'));

    try {
      await axios.post("https://api.ngomongdong.com/saverelate", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Increment or decrement relate_point based on button click state
      if (isRelateClicked) {
        setRelatePoint((prev) => prev - 1); // decrement if already clicked
      } else {
        setRelatePoint((prev) => prev + 1); // increment on first click
      }
      setIsRelateClicked(!isRelateClicked); // toggle the clicked state
    } catch (error) {
      if (error.response.status === 400) {
        // console.log("Error:", error.response.data);
      }
    }
  };

  // passed in as param (i dont know why the "idpostreal" is not working)
  const getRelateCond = async (idpostrealParam) =>{ // blm selesai
    const formData = new FormData();
    formData.append("postidencry", id); 
    formData.append("postid", idpostrealParam);
    // for user validation
    formData.append("usernameTang", win.getItem('username')); // 'Tanggaper' is current user login info
    formData.append("pass", win.getItem('password'));

    // console.log(idpostrealParam+"relat");

    try {
        const resp_ = await axios.post("https://api.ngomongdong.com/relatecondget", formData, {
            headers:{
                "Content-Type": "multipart/form-data"
            }
        });
        // navigate("");
        // Set the condition of the relate button
        setIsRelateClicked(resp_.data.msg);
        // Set the condition of the delete button
        setisDeleteAvailable(resp_.data.msgDel);
        // console.log(resp_.data.msgDel);
        
    } catch (error) {
        // console.log("error.response.status");
        if (error.response.status == 400){
            // console.log(error.response + "error bangsat");
        }

        
    }

  }
  
  

  const saveReply = async(e, idcomment, username_com_post) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("contentReply", Replytcontent); 
    formData.append("postidencry", id); 
    formData.append("postid", idpostreal);
    formData.append("commentid", idcomment);
    formData.append("usernameCom", username_com_post);
    formData.append("usernameCur", username_post);
    // for user validation
    formData.append("usernameTang", win.getItem('username'));
    formData.append("pass", win.getItem('password'));

    setLoadingSaveRep(true); // Stop loading after fetching or error
    try {
        await axios.post("https://api.ngomongdong.com/replypost", formData, {
            headers:{
                "Content-Type": "multipart/form-data"
            }
        });
        // navigate("");
        getCommentsByPostId();
        setReplytContent("");

        
    } catch (error) {
        // console.log(error.response.status);
        if (error.response.status == 400){
            // console.log(error.response + "error bangsat");
        }

        
    }
    finally {
      setLoadingSaveRep(false); // Stop loading after fetching or error
    }

    // when replies post is clicked
    getRepliesByCommentId(idcomment); // to reload the replies
    setReplytContent("");


  

  


  }
  const getUser = async(e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("username", username); // nama "username" sesuai di backend
    formData.append("pass", password);

    try {
        const response = await axios.post("https://api.ngomongdong.com/userpost", formData, {
            headers:{
                "Content-Type": "multipart/form-data"
            }
        });
        // store to local storage if login success
        // console.log(response.data.user.password)
        win.setItem('username', response.data.user.username);
        win.setItem('password', response.data.user.password);
        navigate("/", {replace: true});
        
    } catch (error) {
        // console.log(error.response.status);
        if (error.response.status == 400){
            setLoginError("true");
        }

        
    }

  }



  if (loading) {
    return <p className="text-center text-gray-500">Loading curhatan...</p>;
    
  }

    return (
    

<div class="antialiased mx-auto max-w-screen-sm bg-[#161616] p-10 mt-5">


  <div className="bg-[#121212] rounded-md p-5 mb-4 break-words relative"> 
    {/* Kebab menu button */}
    <div className="absolute top-2 right-2">
    {isDeleteAvailable && (
      <button onClick={toggleKebabMenu}>
        <BiDotsVerticalRounded className="text-white" size={28} />
      </button>
    )}

      {isKebabMenuOpen && (
        <div className="origin-top-right absolute right-0 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
          <div className="py-1 " role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
            <button
              onClick={confirmDelete}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              role="menuitem"
            >
              Hapus Curhatan
            </button>
          </div>
        </div>
      )}
    </div>

    {/* Post content */}
    <h2 className="text-3xl text-white font-light font-hand mb-1">{title}</h2>
    <div className="flex flex-row flex-wrap mb-2 gap-1">
      {tags.map((tag) => (
        <Link
          to={`/searchtags?query=${tag.tag_name}`}
          className="bg-black text-gray-300 rounded-md text-sm px-1.5 py-1 mr-2 mt-0.5"
        >
          {tag.tag_name}
        </Link>
      ))}
    </div>
        <p className="text-gray-400 mb-10">
          Di post oleh <Link to={`/usr/${username_post}`} className=" hover:text-gray-300">@{username_post}</Link> {timeAgo} {' '} {new Date(time_post).toLocaleDateString('id-ID', { day: '2-digit', month: '2-digit', year: '2-digit' })}
        </p>
    <p className="mt-2 text-white">{content}</p>


    

    {/* Action buttons */}
    <div className="mt-3 flex flex-row items-center">
      <button
        onClick={saveRelatePoint}
        type="button"
        className={`py-1 ${isRelateClicked ? 'text-green-500 hover:bg-gray-800 hover:rounded-xl' : 'text-gray-700 hover:text-green-500 hover:bg-gray-800 hover:rounded-xl'}`}
      >
        <FaRegHandshake size={28} />
      </button>
      <p className="ml-2.5 text-lg text-gray-700">{relate_point}</p>
      <p className="ml-1 text-lg text-gray-700">Relate Points</p>

      <BiMessage className="text-gray-700 ml-10 mt-1" size={25} />
      <p className="ml-2 text-lg text-gray-700">{comment}</p>
      <p className="ml-1 text-lg text-gray-700">Comments</p>
    </div>

    {/* Modal for confirming delete */}
    {isModalOpen && (
      <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
        <div className="bg-zinc-900 rounded-md shadow-md p-6">
          <h3 className="text-xl font-semibold mb-4 text-white">Yakin nih mau di hapus curhatannya ?</h3>
          <div className="flex justify-end">
            <button
              onClick={deletePost}
              className="px-4 py-2 bg-red-500 text-white rounded-md mr-2"
            >
              Iya, Hapus
            </button>
            <button
              onClick={() => setIsModalOpen(false)}
              className="px-4 py-2 bg-blue-500 text-white rounded-md"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    )}
  </div>

  
  <div class="rounded-md p-4 bg-[#121212] mb-10">
    <h3 class="text-lg text-white font-semibold mb-2">Tambahkan saran</h3>
    <form onSubmit={saveComment}>
      <div class="mb-4">
        <label class="block text-gray-700 font-bold mb-5" for="comment">
          
        </label>

        <textarea
          class="shadow appearance-none bg-[#121212] border rounded w-full py-2 px-3 text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
          id="comment"
          rows="4"
          placeholder="Kasih saran..."
          required
          value={Commentcontent}
          onChange={(e) => setCommentContent(e.target.value)}
        ></textarea>
      </div>
      <div class="flex items-center justify-end">
        <button
          disabled={loadingSaveCom}
          className={`${
            loadingSaveCom ? 'bg-blue-500 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-500'
          } text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
          type="submit"
        >
          {/* Post Comment */}
          Post Saran
        </button>
      </div>
    </form>
  </div>

  <h3 class="mb-4 text-lg font-semibold text-gray-200">Kumpulan saran</h3>

  <div class="space-y-4">

  {comments.map((comment)=>(
  <div class="flex">
      <div className="flex-shrink-0 mr-3">
        <Link to={`/usr/${comment.user.username}`}>
          <img className="mt-2 rounded-full w-8 h-8 sm:w-10 sm:h-10" src={comment.user.url} alt={comment.user.username} />
        </Link>
      </div>
      <div class="flex-1 border border-gray-700 rounded-lg px-4 py-2 sm:px-6 sm:py-4 leading-relaxed">
        <strong className='text-white'>{comment.user.first_name}</strong> <span class="text-xs text-gray-400 ml-1">{new Date(comment.createdAt).toLocaleDateString('id-ID', { day: '2-digit', month: 'short' })} {' '}
        {new Date(comment.createdAt).toLocaleTimeString([], { hour12: false, hour: '2-digit', minute: '2-digit' })}</span>
        <p class="text-sm text-white">
          {comment.comment_content}
        </p>
        <div class="mt-4 flex items-center">
        {comment.replies_count > 0 && (
          <div>
            {!isDropdownRepliesOpen[comment.comment_id] && (
              <div className="flex flex-row">
                <div className="flex -space-x-2 mr-2 mt-0.5">
                  {/* Map over the URLs array to display images */}
                  {comment.urls.map((url, index) => (
                    <img 
                      key={index} 
                      className="rounded-full w-6 h-6 border border-white" 
                      src={url} 
                      alt={`Reply ${index + 1}`} 
                    />
                  ))}
                </div>
                <button onClick={() => toggleDropdownReplies(comment.comment_id)} type="button" className="text-gray-300 hover:bg-gray-800 hover:rounded-lg hover:text-white py-1 px-1 text-sm">
                  <div className="text-sm text-gray-500 font-semibold mb-0.5">
                    {comment.replies_count} Balasan
                  </div>
                </button>
              </div>
            )}
            {isDropdownRepliesOpen[comment.comment_id] && (
              <button onClick={() => toggleDropdownReplies(comment.comment_id)} type="button" className="text-gray-300 hover:bg-gray-800 hover:rounded-lg hover:text-white py-1 px-1 text-sm">
                <h4 className="my-2 uppercase tracking-wide text-gray-400 font-bold text-xs">Balasan</h4>
              </button>
            )}

            
          </div>
        )}
            <button
              onClick={() => toggleDropdownReply(comment.createdAt)}
              type="button"
              className="text-gray-300 hover:bg-gray-800 hover:rounded-lg hover:text-white py-1 px-1 text-sm font-medium ml-2"
            >
              <div className="flex flex-row items-center">
                <BiMessage className="text-gray-400 font-bold text-xs mt-0.5" size={15}></BiMessage>
                <p className="text-gray-500 text-s mb-0.5 ml-1">Balas</p>
              </div>
            </button>
        </div>

        <div class="space-y-4 mt-4">
          {isDropdownRepliesOpen[comment.comment_id] && (
           
                  <div>
                     {replies[comment.comment_id] && replies[comment.comment_id].map((reply, index) => (
                    <div class="flex mb-3">
                        <div class="flex-shrink-0 mr-3">
                          <Link to={`/usr/${reply.user.username}`}>
                            <img class="mt-3 rounded-full w-6 h-6 sm:w-8 sm:h-8" src={reply.user.url} alt=""></img>
                          </Link>
                        </div>
                        <div class="flex-1 bg-[#121212] rounded-lg px-4 py-2 sm:px-6 sm:py-4 leading-relaxed">
                           <strong className='text-white'>{reply.user.first_name}</strong> <span class="text-xs text-gray-400 ml-0.5"> {new Date(reply.createdAt).toLocaleDateString('id-ID', { day: '2-digit', month: 'short' })} {' '}
                             {new Date(reply.createdAt).toLocaleTimeString([], { hour12: false, hour: '2-digit', minute: '2-digit' })}
                           </span>
                          <p class="text-xs sm:text-sm text-white">
                          {reply.reply_content}
                          </p>
                          
                        </div>
                    </div>
                    ))}
                  </div>
              
            )}
          {isDropdownReplyOpen[comment.createdAt] && (
                  <div class="flex mt-2">
                    <div class="flex-shrink-0 mr-3">
                    </div>
                    <div class="flex-1 bg-[#121212] rounded-lg px-4 py-2 sm:px-6 sm:py-4 leading-relaxed">
                      <strong className='text-white'></strong> <span class="text-xs text-gray-400"></span>
                      <h3 class="text-lg text-gray-400 font-semibold mb-2 mt-1">Balasan saran</h3>
                        <form onSubmit={(e) => saveReply(e, comment.comment_id, comment.user.username)}>
                          <div class="mb-4">
                            <label class="block text-gray-700 font-bold mb-5" for="comment">
                              
                            </label>

                            <textarea
                              class="shadow appearance-none bg-[#121212] border rounded w-full py-2 px-3 text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                              id="comment"
                              rows="4"
                              placeholder ="Kasih balasan saran..."
                              required
                              value={Replytcontent}
                              onChange={(e) => setReplytContent(e.target.value)}
                            ></textarea>
                          </div>
                          <div class="flex items-center justify-end">
                            <button
                              disabled={loadingSaveRep}
                              className={`${
                                loadingSaveRep ? 'bg-blue-500 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-500'
                              } text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
                              type="submit"
                            >
                              {/* Post Reply */}
                              Post Saran
                            </button>
                          </div>
                        </form>
                    </div>
                  </div>
          )}

        </div>

        
      </div>
    </div>
  ))}  
{/* END */}
        {/* Login Modal */}
        {!isUserLoggedIn && (
          
  <div className="fixed inset-0 bg-black bg-opacity-75 flex flex-col justify-center items-center z-50">
    <h1 className='text-5xl mb-10 font-hand text-gray-100'>NgomongDong.com</h1>
    <div className="bg-[#000300] p-5 md:p-20 rounded-md shadow-xl">
      <h2 className="text-center mb-20 text-lg text-[#00df9a] border-b border-gray-800">
        YUK LOGIN DULU !
      </h2>
      <form onSubmit={getUser} className="flex flex-col mb-10">
        <div className='flex flex-row mr-10 mb-2'>
          <AiOutlineUser size={20} className='text-white mt-3 mr-5'></AiOutlineUser>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            className="px-4 py-2 mb-3 border border-gray-300 rounded"
          />
        </div>
        <div className='flex flex-row mb-5'>
          <AiOutlineKey size={20} className='text-white mt-3 mr-5'></AiOutlineKey>
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="px-4 py-2 border border-gray-300 rounded"
          />
        </div>
        {loginError === "true" && (
          <div className='mb-8 ml-11 mt-[-2]'>
            <p className="text-red-500 text-xs">Username or password is wrong</p>
          </div>
        )}
        <button type="submit" className="px-4 my-6 mx-auto py-3 bg-[#00df9a] text-black w-[200px] rounded-md font-medium">
          Log In
        </button>
      </form>

      {/* Add the "Don't have an account? Register" link here */}
      <p className="text-center text-sm mb-5 text-white">
        Belum pernah daftar? <Link to="/register" className="text-[#00df9a] hover:underline">Daftar</Link>
      </p>
    </div>
  </div>
)}

{isModalMessageOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center">
          <div className="bg-zinc-900 rounded-md shadow-md p-6">
            <h3 className="text-xl font-semibold mb-4 text-white">Berhasil !</h3>
            <p className='text-white'>Curhatan berhasil di hapus</p>
            <button
              onClick={() => setIsModalMessageOpen(false)}
              className="mt-4 px-4 py-2 bg-red-500 text-white rounded-md"
            >
              Tutup
            </button>
          </div>
        </div>
      )}
    {/* <div class="flex">
      <div class="flex-shrink-0 mr-3">
        <img class="mt-2 rounded-full w-8 h-8 sm:w-10 sm:h-10" src="https://images.unsplash.com/photo-1604426633861-11b2faead63c?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=200&h=200&q=80" alt=""></img>
      </div>
      <div class="flex-1 border border-gray-700 rounded-lg px-4 py-2 sm:px-6 sm:py-4 leading-relaxed">
        <strong className='text-white'>Sarah</strong> <span class="text-xs text-gray-400">3:34 PM</span>
        <p class="text-sm text-white">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
          sed diam nonumy eirmod tempor invidunt ut labore et dolore
          magna aliquyam erat, sed diam voluptua.
        </p>
        <div class="mt-4 flex items-center">
          <div class="flex -space-x-2 mr-2">
            <img class="rounded-full w-6 h-6 border border-white" src="https://images.unsplash.com/photo-1554151228-14d9def656e4?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=100&h=100&q=80" alt=""></img>
            <img class="rounded-full w-6 h-6 border border-white" src="https://images.unsplash.com/photo-1513956589380-bad6acb9b9d4?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=100&h=100&q=80" alt=""></img>
          </div>
          <div class="text-sm text-gray-500 font-semibold">
            5 Replies
          </div>
        </div>
      </div>
    </div> */}
{/* 
    <div class="flex">
      <div class="flex-shrink-0 mr-3">
        <img class="mt-2 rounded-full w-8 h-8 sm:w-10 sm:h-10" src="https://images.unsplash.com/photo-1604426633861-11b2faead63c?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=200&h=200&q=80" alt=""></img>
      </div>
      <div class="flex-1 border border-gray-700 rounded-lg px-4 py-2 sm:px-6 sm:py-4 leading-relaxed">
        <strong className='text-white'>Sarah</strong> <span class="text-xs text-gray-400">3:34 PM</span>
        <p class="text-sm text-white">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
          sed diam nonumy eirmod tempor invidunt ut labore et dolore
          magna aliquyam erat, sed diam voluptua.
        </p>
        <div className='flex flex-row items-center'>
          <h4 class="my-5 uppercase tracking-wide text-gray-400 font-bold text-xs">Replies</h4>
          <button
                    onClick={toggleDropdownReply}
                    type="button"
                    className="text-gray-300 hover:bg-gray-800 hover:rounded-lg hover:text-white py-1 px-1 text-sm font-medium ml-2"
                  >
                  <div className='flex flex-row items-center'>
                    <BiMessage className='text-gray-400 font-bold text-xs mt-0.5' size={15}></BiMessage>
                    <p className='text-gray-500 text-s mb-0.5 ml-1'>Reply</p>
                  </div>
          </button>
        </div>

        <div class="space-y-4">
          <div class="flex">
            <div class="flex-shrink-0 mr-3">
              <img class="mt-3 rounded-full w-6 h-6 sm:w-8 sm:h-8" src="https://images.unsplash.com/photo-1604426633861-11b2faead63c?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=200&h=200&q=80" alt=""></img>
            </div>
            <div class="flex-1 bg-[#121212] rounded-lg px-4 py-2 sm:px-6 sm:py-4 leading-relaxed">
              <strong className='text-white'>Sarah</strong> <span class="text-xs text-gray-400">3:34 PM</span>
              <p class="text-xs sm:text-sm text-white">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                sed diam nonumy eirmod tempor invidunt ut labore et dolore
                magna aliquyam erat, sed diam voluptua.
              </p>
            </div>
          </div>
          <div class="flex">
            <div class="flex-shrink-0 mr-3">
              <img class="mt-3 rounded-full w-6 h-6 sm:w-8 sm:h-8" src="https://images.unsplash.com/photo-1604426633861-11b2faead63c?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=200&h=200&q=80" alt=""></img>
            </div>
            <div class="flex-1 bg-[#121212] rounded-lg px-4 py-2 sm:px-6 sm:py-4 leading-relaxed">
              <strong className='text-white'>Sarah</strong> <span class="text-xs text-gray-400">3:34 PM</span>
              <p class="text-xs sm:text-sm text-white">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                sed diam nonumy eirmod tempor invidunt ut labore et dolore
                magna aliquyam erat, sed diam voluptua.
              </p>
            </div>
          </div>
          {isDropdownReplyOpen && (
                  <div class="flex">
                  <div class="flex-shrink-0 mr-3">
                  </div>
                  <div class="flex-1 bg-[#121212] rounded-lg px-4 py-2 sm:px-6 sm:py-4 leading-relaxed">
                    <strong className='text-white'></strong> <span class="text-xs text-gray-400"></span>
                    <h3 class="text-lg text-gray-400 font-semibold mb-2 mt-1">Balasan saran</h3>
                      <form onSubmit={saveReply}>
                        <div class="mb-4">
                          <label class="block text-gray-700 font-bold mb-5" for="comment">
                            
                          </label>

                          <textarea
                            class="shadow appearance-none bg-[#121212] border rounded w-full py-2 px-3 text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                            id="comment"
                            rows="4"
                            placeholder ="Kasih balasan saran..."
                            value={Replytcontent}
                            onChange={(e) => setReplytContent(e.target.value)}
                          ></textarea>
                        </div>
                        <div class="flex items-center justify-end">
                          <button
                            class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="submit"
                          >
                            Post Comment
                          </button>
                        </div>
                      </form>
                  </div>
                </div>
          )}
        </div>
      </div>
    </div> */}
  </div>
</div>



  )
}

export default CurrentPost