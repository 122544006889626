import React, { useEffect, useState } from 'react';
import {AiOutlineClose, AiOutlineKey, AiOutlineMenu, AiOutlineUser} from 'react-icons/ai'
import axios from 'axios';
import { useNavigate, Link} from 'react-router-dom';


const LoginScreen = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [loginError, setLoginError] = useState('false'); // checking if login is error or not
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  // local stor var
  const win = window.localStorage; 


  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform login logic here
  };

  // redirect to explore page if user stil logged in ( local storage )
  useEffect(()=>{
    if (win.getItem('username') && win.getItem('password')){
      navigate("/explore");

    }
  },[])

  const getUser = async(e) => {
    e.preventDefault();
    setLoading(true); // Disable the button

    const formData = new FormData();
    formData.append("username", username); // nama "username" sesuai di backend
    formData.append("pass", password);

    try {
        const response = await axios.post("https://api.ngomongdong.com/userpost", formData, {
            headers:{
                "Content-Type": "multipart/form-data"
            }
        });
        // store to local storage if login success
        // console.log(response.data.user.password)
        win.setItem('username', response.data.user.username);
        win.setItem('password', response.data.user.password);
        navigate("/explore", {replace: true});
        
    } catch (error) {
        // console.log(error.response.status);
        if (error.response.status == 400){
            setLoginError("true");
        }

        
    }
    finally {
      setLoading(false); // Re-enable the button after login attempt
    }
    

    ////////////////////////////////////////////////////

    // if (userLoginError) {
    //     return;
    // }

    // else{
    //     try {
    //         await axios.get("https://api.ngomongdong.com/users", formData, {
    //             headers:{
    //                 "Content-Type": "multipart/form-data"
    //             }
    //         });
    //         navigate("/");
            
    //     } catch (error) {
    //         // console.log(error.response.status);
    //         if (error.response.status == 400){
    //             userLoginError = "true";
    //         }

            
    //     }
    //     // Reset name error if name is valid
    //     // setUseridError('');
    //     // setUserPassError('');


    // }
    ///////////////////////////////////////////////////////////
    
      
    


  }

  return (
    // <div className="flex flex-col md:flex-row justify-center items-center min-h-screen bg-[#191919]">
    <div className="flex flex-col md:flex-row justify-center items-center min-h-screen bg-gradient-to-t from-def-green md:bg-gradient-to-l">
      <div className='m-10'>
        <h1 className='w-full text-5xl font-hand text-gray-100'>NgomongDong.com</h1>
      </div>
      <div className="bg-[#000300] p-5 md:p-20 rounded-md shadow-xl">
        <h2 className="text-center mb-20 text-lg text-[#00df9a] border-b border-gray-800">WELCOME BACK! PARA CURHATER</h2>
        <form onSubmit={getUser} className="flex flex-col mb-10">
          <div className='flex flex-row mr-10 mb-2'>
            <AiOutlineUser size={20} className='text-white mt-3 mr-5'></AiOutlineUser>
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              className="px-4 py-2 mb-3 border border-gray-300 rounded"
            />
          </div>
          <div className='flex flex-row mb-5'>
            <AiOutlineKey size={20} className='text-white mt-3 mr-5'></AiOutlineKey>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="px-4 py-2 border border-gray-300 rounded"
            />
          </div>
          {loginError === "true" && (
            <div className='mb-8 ml-11 mt-[-2]'>
              <p className="text-red-500 text-xs">Username atau password salah</p>
            </div>
          )}
          <button
            type="submit"
            disabled={loading} // Disable the button if loading is true
            className={`px-4 my-6 mx-auto py-3 w-[200px] rounded-md font-medium ${loading ? "bg-gray-500 text-gray-300" : "bg-[#00df9a] text-black"
              }`}
          >
            {loading ? "Tunggu Sebentar..." : "Log In"}
          </button>
        </form>

        {/* Add the "Don't have an account? Register" link here */}
        <p className="text-center text-sm mb-5 text-white">
          Belum pernah daftar ? <Link to="/register" className="text-[#00df9a] hover:underline">Daftar</Link>
        </p>
      </div>
    </div>

  );
};

export default LoginScreen;
