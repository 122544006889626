import React, { useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const SignUpScreen= () => {
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [userid, setUserid] = useState('');
  const [useridError, setUseridError] = useState('');
  const [useridErrorContainsSpace, setUseridErrorContainsSpace] = useState('');
  const [useridErrorTaken, setUseridErrorTaken] = useState('');
  const [userPassError, setUserPassError] = useState('');
  const [password, setPassword] = useState('');

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
  };


  const saveUser = async(e) => {
    e.preventDefault();
    setLoading(true); // Disable the button

    const formData = new FormData();
    formData.append("username", userid); // nama "username" sesuai di backend
    formData.append("firstname", firstname); 
    formData.append("lastname", lastname);
    formData.append("pass", password);
    if (!isUserValid) {
        setLoading(false); 
        setUseridErrorTaken('false');
        if (/\s/.test(userid)){{ // check if contains spaces or tabs
          setUseridErrorContainsSpace('true');
        }}
        else{
          setUseridErrorContainsSpace('false');
        }
        if (!(userid.length > 5)){
          setUseridError('true');

        }
        else{
          setUseridError('false');
        }
        if (!isPasswordValid){ // check if password is not long enough
            setUserPassError('true');
            // return;
        }
        else{
            setUserPassError('false');
        }
        return;
    }

    else if (!isPasswordValid){
        setUserPassError('true');
        setUseridErrorTaken('false');
        setLoading(false); 
        if (!isUserValid){ // check if userid is not long enough
            setUseridError('true');
            setLoading(false); 
            // return;
        }
        else{
            setUseridError('false');
            setUseridErrorContainsSpace('false');

        }
        return;

    }

    

    else if (isUserValid && isPasswordValid){
        try {
            await axios.post("https://api.ngomongdong.com/users", formData, {
                headers:{
                    "Content-Type": "multipart/form-data"
                }
            });
            navigate("/login");
            setUseridErrorTaken('false');

            
        } catch (error) {
            // console.log(error.response.status);
            if (error.response.status == 400){
                setUseridErrorTaken('true');
            }
        }
        finally {
          setLoading(false); // Re-enable the button after login attempt
        }
        
        // Reset name error if name is valid (right)
        setUseridError('false');
        setUserPassError('false');
        setUseridErrorContainsSpace('false');


    }
      
      
    


  }

  const isUserValid = (userid.length > 5) && !(/\s/.test(userid)); // checking char userid
  const isPasswordValid = password.length > 6; // checking char password

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#000300] bg-pack-train bg-cover bg-fixed">
      <div>
        <h1 className='w-full text-5xl font-hand text-gray-100 mb-20'>NgomongDong.com</h1>
      </div>
      <div className="max-w-md w-full px-6 py-11 bg-[#000300] rounded-lg shadow-md border border-gray-500 ">
        <h2 className="text-3xl font-semibold text-center text-gray-100 mb-6">Sign Up</h2>
        <p className='text-center text-gray-500 mb-6'>Kita semua butuh tempat untuk curhat.</p>
        <form onSubmit={saveUser}>
          <div className="mb-4 grid grid-cols-4">
            <div className='col-span-2 mr-2'>
                <label htmlFor="firstname" className="block text-[#00df9a] text-sm font-light mb-2">
                 First Name
                </label>
                <input
                type="text"
                id="firstname"
                className="w-full px-3 py-2 border rounded focus:outline-none focus:border-blue-500"
                placeholder="Nama pertama"
                value={firstname}
                onChange={(e) => setFirstName(e.target.value)}
                required
                />
            </div>
            <div className='col-span-2'>
                <label htmlFor="lastname" className="block text-[#00df9a] text-sm font-light mb-2">
                Last Name
                </label>
                <input
                type="text"
                id="lastname"
                className="w-full px-3 py-2 border rounded focus:outline-none focus:border-blue-500"
                placeholder="Nama terakhir"
                value={lastname}
                onChange={(e) => setLastName(e.target.value)}
                />
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-[#00df9a] text-sm font-light mb-2">
              Username
            </label>
            <input
              type="text"
              id="email"
              className="w-full px-3 py-2 border rounded focus:outline-none focus:border-blue-500"
              placeholder="Masukan username"
              value={userid}
              maxLength={25}
              onChange={(e) => setUserid(e.target.value)}
              required
            />
            {useridError=="true" && (
              <p className="text-red-500 text-sm mt-1">Username harus lebih dari 5 karakter</p>
            )}
            {useridErrorContainsSpace=="true" && (
              <p className="text-red-500 text-sm mt-1">Username tidak boleh berisi spasi</p>
            )}
            {useridErrorTaken=="true" && (
              <p className="text-red-500 text-sm mt-1">Username sudah di ambil</p>
            )}
          </div>
          <div className="mb-6">
            <label htmlFor="password" className="block text-[#00df9a] text-sm font-light mb-2">
              Password
            </label>
            <input
              type="password"
              id="password"
              className="w-full px-3 py-2 border rounded focus:outline-none focus:border-blue-500"
              placeholder="Masukan password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            {userPassError=="true" && (
              <p className="text-red-500 text-sm mt-1">Password harus lebih dari 6 karakter</p>
            )}
          </div>
          <button
            type="submit"
            disabled={loading} // Disable the button if loading is true
            className="w-full bg-[#000300] hover:bg-[#133d30] text-[#00df9a] font-bold py-2 px-4 rounded border border-gray-900"
          >
            {loading ? "Tunggu Sebentar..." : "Sign Up"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default SignUpScreen;
