export function getCroppedImg(imageSrc, crop, fileName) {
    return new Promise((resolve, reject) => {
        const image = new Image();
        
        // Enable cross-origin support
        image.crossOrigin = "anonymous"; 
        
        // Set image source
        image.src = imageSrc;
        
        // When the image loads, create the canvas and context
        image.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            
            // Check if the context was successfully created
            if (!ctx) {
                reject(new Error("Canvas context is null"));
                return;
            }
            
            // Set canvas dimensions to the crop area
            canvas.width = crop.width;
            canvas.height = crop.height;

            // Draw the image on the canvas based on the crop dimensions
            ctx.drawImage(
                image,
                crop.x, crop.y, crop.width, crop.height, // Source coordinates
                0, 0, crop.width, crop.height              // Destination coordinates
            );

            // Convert the cropped canvas content to a Blob
            canvas.toBlob((blob) => {
                if (!blob) {
                    reject(new Error("Canvas is empty, failed to create blob"));
                    return;
                }
                blob.name = fileName;
                resolve(blob); // Resolve with the blob for preview or upload
            }, 'image/jpeg', 0.5); // Adjust image format and quality as needed
        };

        // Handle image loading errors
        image.onerror = (error) => reject(new Error("Failed to load image"));
    });
}
