import React from 'react'
import Logo from '../assets/logo_levitt_2.png'

import {
    FaDribbbleSquare,
    FaFacebookSquare,
    FaGithubSquare,
    FaInstagram,
    FaLinkedin,
    FaTwitterSquare,

} from 'react-icons/fa';
const Footer = () => {
  return (
    <div className='max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300'>
        <div>
            {/* <h1 className='w-full text-3xl font-bold text-[#00df9a]'>Levitt8 Technologies.</h1> */}
            <img className='w-[400px] mx-auto my-4 rounded-md' src={Logo} alt='/'/>
              <p>&copy; 2020-2024 Levitt8 Technologies. All rights reserved.</p>
              <p className='text-sm text-zinc-600'>Developed and created by Daffa Hafizh</p>
              <p className='text-sm text-zinc-600'>Email: daffa.hafizhshafa@gmail.com</p>
              {/* <p className='text-sm text-zinc-600'>Instagram: daffa.hafizhshafa@gmail.com</p> */}
            <div className='flex justify-between md:w-[75%] my-6'>
                  <a className='hover:text-gray-400' href="https://www.linkedin.com/in/daffa-h-929b42119/" target="_blank" rel="noopener noreferrer">
                      <FaLinkedin size={30} />
                  </a>
                  <a className='hover:text-gray-400' href="https://instagram.com/daffaa.hafizh" target="_blank" rel="noopener noreferrer">
                      <FaInstagram size={30} />
                  </a>
                  <FaTwitterSquare size={30} />
                  <a className='hover:text-gray-400' href="https://github.com/badInventor101" target="_blank" rel="noopener noreferrer">
                      <FaGithubSquare size={30} />
                  </a>
                {/* <FaDribbbleSquare size={30}/> */}
            </div>
        </div>

        <div className='lg:col-span-2 flex justify-between mt-6'>
            <div>
                <h6 className='font-medium text-gray-400'> Social Media </h6>
                <ul>
                    <li className='py-2 text-sm hover:text-gray-400'>
                        <a href="https://instagram.com/daffaa.hafizh" target="_blank" rel="noopener noreferrer">
                              Instagram
                        </a>
                    </li>
                    {/* <li className='py-2 text-sm'>Marketing</li>
                    <li className='py-2 text-sm'>Commerce</li>
                    <li className='py-2 text-sm'>Insights</li> */}
                </ul>
            </div>
            {/* <div>
                <h6 className='font-medium text-gray-400'> Solutions </h6>
                <ul>
                    <li className='py-2 text-sm'>Analytics</li>
                    <li className='py-2 text-sm'>Marketing</li>
                    <li className='py-2 text-sm'>Commerce</li>
                    <li className='py-2 text-sm'>Insights</li>
                </ul>
            </div>
            <div>
                <h6 className='font-medium text-gray-400'> Solutions </h6>
                <ul>
                    <li className='py-2 text-sm'>Analytics</li>
                    <li className='py-2 text-sm'>Marketing</li>
                    <li className='py-2 text-sm'>Commerce</li>
                    <li className='py-2 text-sm'>Insights</li>
                </ul>
            </div> */}

        </div>

        

       

    </div>
  )
}

export default Footer