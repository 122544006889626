import React, { useEffect, useState } from 'react';
import { Link, useLocation  } from 'react-router-dom';
import axios from 'axios';

import { useNavigate, useParams } from 'react-router-dom';

const UserSearch = ({ setSearchResultsPosts }) => {

  // State for search query and search results FOR USERS
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state search users
//   const [searchResultsPostsCurrent, setSearchResultsPostsCurrent] = useState([]);

  // const {id} = useParams(); // for grabing ID from the URL
  const location = useLocation();
  const query = new URLSearchParams(location.search).get('query');

  const navigate = useNavigate();



 

  // Handle search submit
//   const handleSearch = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.get(`https://api.ngomongdong.com/searchusers?query=${searchQuery}`);
//       setSearchResults(response.data);
//     } catch (error) {
//       console.error("Error fetching search results", error);
//     }
//   };


  // useEffect(()=>{
  //   getUsersAndPostById();

  // }, [id]);

  useEffect(() => {
    if (query) {
      getUsersAndPostById();
    }
  }, [query]);

  // const getUsersAndPostById = async() =>{
  //   try {
  //       const response = await axios.get(`https://api.ngomongdong.com/search?query=${query}`);
  //       setSearchResults(response.data.users);
  //       // setSearchResultsPostsCurrent(response.data.posts);

  //       // pass it to the parent NEWWWW (1. PASS THE POSTS DATA TO THE PARENT AS PROPS TO APP.JS)
  //       setSearchResultsPosts(response.data.posts);
  //       // console.log(response.data.posts)
  //   } catch (error) {
  //       // console.error("Error fetching search results", error);
  //   }
    
  // }

  const getUsersAndPostById = async () => {
    setLoading(true); // Start loading
    try {
      const response = await axios.get(`https://api.ngomongdong.com/search?query=${query}`);
      setSearchResults(response.data.users);
      setSearchResultsPosts(response.data.posts);
      // Set other data if needed
    } catch (error) {
      console.error("Error fetching search results", error);
    }
    setLoading(false); // Stop loading
  };

  return (
    <div className='max-w-full m-7 rounded-sm mt-16 py-1 overflow-y-auto bg-zinc-950'>
      <div className="max-w-lg ml-10 mt-10">
        <div>
          <p className='text-white text-lg mb-10 ml-5'>Users</p>

          {/* Conditionally render loading message */}
          {loading ? (
            <p className="text-gray-500 text-center">Tunggu sebentar yaa...</p>
          ) : (
            <div className="max-h-80">
              {searchResults.length > 0 ? (
                searchResults.map((user) => (
                  <Link to={`/usr/${user.username}`} key={user.username}>
                    <div className="p-4 mb-6 mr-7 md:mr-0 border rounded-lg hover:bg-gray-900 cursor-pointer">
                      <div className="flex items-center">
                        {user.url ? (
                          <img src={user.url} alt={user.username} className="w-10 h-10 rounded-full mr-3" />
                        ) : (
                          <div className="w-10 h-10 bg-gray-300 rounded-full mr-3"></div>
                        )}
                        <div>
                          <h3 className="font-bold text-white">{user.first_name} {user.last_name}</h3>
                          <p className="text-sm text-gray-500">@{user.username}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))
              ) : (
                <p className='text-white'>User tidak ditemukan.</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>

  );
};

export default UserSearch;
