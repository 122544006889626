import { useEffect } from "react";

const AdComponent = () => {
    useEffect(() => {
        if (window.adsbygoogle && window.adsbygoogle.length === 0) {
          try {
            window.adsbygoogle.push({});
          } catch (e) {
            console.error("Adsbygoogle push error:", e);
          }
        }
      }, []);

  return (
    <div>
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-3290834572622811" // replace with your AdSense publisher ID
        data-ad-slot="4577623035"  // replace with your ad slot ID
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </div>
  );
};

export default AdComponent;
