import React, { useEffect, useState, useCallback  } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Cropper from 'react-easy-crop';
import Modal from "react-modal";
import { getCroppedImg } from './canvas-to-image.js';
import { AiOutlineZoomIn, AiOutlineZoomOut } from 'react-icons/ai';


// Modal styles
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "20px",
    width: "90%",
    maxWidth: "400px",
    backgroundColor: "#18181b", // Change this to your desired background color
    color: "#ffffff",           // Change this to your desired text color
    // border: "1px solid #cccccc", // Optional border color
    // borderRadius: "8px"
  },
};

const SettingsUser = () => {
  // States for user info
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [profilePicture, setProfilePicture] = useState(""); // for profile image

  // State for storing the uploaded image URL
  const [previewImage, setPreviewImage] = useState("");
  const [ImageToUp, setFileImgToUp] = useState(""); // WHEN UPLOADING NEW IMAGE TO THE SERVER (FILE)

  // for user's zooming in / out the profile picture
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const [image, setImage] = useState(null); // temporary store users image when updating

  // State for modal visibility
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalEditImageOpen, setIsModalEditImageOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);


  const navigate = useNavigate();
  // importing local storage
  const win = window.localStorage;

  useEffect(() => {
    checkUserLoggedIn();
    
  }, []);

  const checkUserLoggedIn = () => {
    if (localStorage.getItem('username') != null) {
      getUserData();
        
    } else {
        navigate("/login");

    }
};

  const getUserData = async () => {

    const formData = new FormData();
    formData.append("usernameTang", win.getItem('username'));

    setLoading(true);
    try {
      const resp_ = axios.post("https://api.ngomongdong.com/getuserdata", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });

      setFirstName((await resp_).data.user.first_name);
      setLastName((await resp_).data.user.last_name);
      setProfilePicture((await resp_).data.user.url);
    } catch (error) {
      if (error.response.status === 400) {
        // console.log(error.response + "error bangsat");
      }
    }
    finally {
      setLoading(false); // Stop loading after fetching or error
    }
  }

  // Handler for image input change
  // const handleImageChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const url = URL.createObjectURL(file);
  //     setImage(url);
  //   }
  //   setFileImgToUp(file);
    
  // };
  const handleImageChange = (e) => {
    const file = e.target.files[0]; // the file from the folder FROM THE USER !!
    if (file) {
      const url = URL.createObjectURL(file);
      setImage(url); // temporary container for the file that just uploaded from the USER !!
      setIsModalEditImageOpen(true); // Open modal on image change
      // console.log("File:", file);
      
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const closeModal = () => {
    setIsModalEditImageOpen(false);
    // setImage(null);
  };

  // Helper to generate cropped image as a blob URL
  const getCroppedImage = async () => {
    // console.log("Image:", image);
    // console.log("Cropped Area Pixels:", croppedAreaPixels);

    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels);
      const previewURL = URL.createObjectURL(croppedImage); // to preview the image after croping

      // Create a File from the Blob
      const file = new File([croppedImage], 'croppedImage.jpg', { type: 'image/jpeg' });
      // console.log("File: ", file);
      setFileImgToUp(file);

      setPreviewImage(previewURL); // to preview the image after croping

      // console.log("Cropped Area Pixels:", previewURL);
      closeModal();
    } catch (e) {
      // console.error(e);
    }
  };

  // Handle user info update submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("profilePicture", ImageToUp);

    formData.append("usernameTang", win.getItem('username'));
    formData.append("pass", win.getItem('password'));

    setLoadingSave(true);
    try {
      const response = await axios.patch("https://api.ngomongdong.com/updateuser", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setIsModalOpen(true); // Show the modal on success
      // After a short delay, navigate to this page for refresh
      setTimeout(() => {
        // refresh page
        window.location.reload();
      }, 20); // 500ms delay, adjust as needed


    } catch (error) {
      // console.error("Error updating user info", error);
      alert("Server masih mengalami kendala teknis, tunggu sebentar yaa.");
    }
    finally {
      setLoadingSave(false); // Stop loading after fetching or error
    }
  };

  return (
    <div>
      {loading ? <p className="text-center text-gray-500">Loading data user...</p> : ""}
      <div className="max-w-md mx-auto mt-10 border-2 rounded-md p-11 border-gray-900">
      <h2 className="text-2xl font-bold mb-10 text-[#00df9a]">Pengaturan</h2>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        {/* Profile Picture */}
        <div className="mb-5">
            <label className="block mb-2 font-semibold text-white">Profile Picture</label>
            {previewImage ? (
              <img src={previewImage} alt="Profile Preview" className="rounded-full w-20 h-20 mb-4 border-2 border-white" />
            ) : (
              <img src={profilePicture} alt="Profile Preview" className="rounded-full w-20 h-20 mb-4 border-2 border-white" />
            )}
            <input type="file" accept="image/*" onChange={handleImageChange}></input>
        </div>
          {/* First Name */}
          <div className="mb-5">
            <label className="block mb-2 text-gray-300 text-sm font-medium">First Name</label>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="w-full p-2 border rounded-md"
              // placeholder="Enter first name" required
              placeholder="Nama pertama" required
            />
          </div>

          {/* Last Name */}
          <div className="mb-5">
            <label className="block mb-2 text-gray-300 text-sm font-medium">Last Name</label>
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="w-full p-2 border rounded-md"
              // placeholder="Enter last name" required
              placeholder="Nama terakhir"
            />
          </div>

          {/* Submit Button */}
          <div className="flex justify-end mt-8">
            <button type="submit" className={`px-4 py-2 rounded-md ${loadingSave ? "bg-blue-400 text-gray-100 cursor-not-allowed" : "bg-blue-500 text-white"
              }`}
            >
              {loadingSave ? "Menyimpan..." : "Simpan"}
            </button>
          </div>
        </form>
      </div>
      {/* Modal for Image Crop */}
      <Modal
        isOpen={isModalEditImageOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Atur Profile Picture"
      >
        <h2 className="text-lg font-bold mb-4">Atur Profile Picture</h2>
        <div className="crop-container relative bg-black " style={{ height: "300px" }}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={1} // square crop
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
        </div>
        <div className="mt-4 flex flex-col items-center">
          <input
            type="range"
            min={1}
            max={3}
            step={0.1}
            value={zoom}
            onChange={(e) => setZoom(e.target.value)}
            className="w-full mt-2"
          />
          <div className="flex justify-between mt-4 w-full">
            <button onClick={closeModal} className="bg-gray-500 text-white py-1 px-4 rounded">
              Cancel
            </button>
            <button
              onClick={getCroppedImage}
              className="bg-[#00df9a] text-white py-1 px-4 rounded"
            >
              Simpan
            </button>
          </div>
        </div>
      </Modal>

      {/* Modal for success */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center">
          <div className="bg-zinc-900 rounded-md shadow-md p-6">
            <h3 className="text-xl font-semibold mb-4 text-white">Update Berhasil !</h3>
            <p className='text-white'>Data kamu sudah berhasil diperbarui</p>
            <button
              onClick={() => setIsModalOpen(false)}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SettingsUser;
