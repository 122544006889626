import React from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Navbar from './components/Navbar';
import Navbaruser from './components/NavbarUser'
import HeroPostPage from './components/HeroPost'
import Hero from './components/Hero';
import Analytics from './components/Analytics';
import Newsletter from './components/Newsletter';
import Cards from './components/Cards';
import Footer from './components/Footer';
import LoginScreen from './components/Login';
import SignUpScreen from './components/Register';
import CreatePostPage from './components/CreatePost';
import CurrentPost from './components/CurrentPost';
import SettingsUser from './components/SettingsUser';
import UserPage from './components/PageUser';
import UserSearch from './components/SearchUser';

import { useState } from 'react';
import TagsSearch from './components/SearchTags';

function App() {
  // 2. STORE THE POSTS DATA TO THE PARENT HERE as "searchResultsPosts""
  const [searchResultsPosts, setSearchResultsPosts] = useState([]); // Lifted state
  return (
    // <div className="App">
    //   <Navbar/>
    //   <Hero/>
    //   <Analytics/>
    //   {/* <Newsletter/>
    //   <Cards/> */}
    //   <Footer/>
    // </div>
    
    <BrowserRouter>
    <Routes>
      <Route path='/' element={
        <div className="App">
           <Navbar/>
           <Hero/>
           <Analytics/>
           {/* <Newsletter/>
           <Cards/> */}
           <Footer/>
         </div>
      }/>
      <Route path='login' element={
        <div className="App">
           <LoginScreen/>
         </div>
      }/>
      <Route path='register' element={
        <div className="App">
           <SignUpScreen/>
         </div>
      }/>

      <Route path='explore' element={
        <div className="App">
           <Navbaruser/>
           <HeroPostPage/>
         </div>
      }/>

      <Route path='createpost' element={
        <div className="App">
           <Navbaruser/>
           <CreatePostPage/>
         </div>
      }/>

      <Route path='currentpost/:id' element={
        <div className="App">
           <Navbaruser/>
           <CurrentPost/>
         </div>
      }/>

      <Route path='usersettings' element={
        <div className="App">
           <Navbaruser/>
           <SettingsUser/>
         </div>
      }/>

      {/* 'usr/id' berbeda dari backend, contoh 'user/wade773' */}
      <Route path='usr/:id' element={
        <div className="App">
          <Navbaruser/>
           <UserPage/>
           <HeroPostPage/>
         </div>
      }/>

      <Route path='/search' element={
        <div className="App">
          <Navbaruser/>
          <UserSearch setSearchResultsPosts={setSearchResultsPosts} />
          {/* 3. PASS IT TO HEROPOSTS PAGE */}
          <HeroPostPage searchResultsPosts={searchResultsPosts} />
         </div>
      }/>

      <Route path='/searchtags' element={
        <div className="App">
          <Navbaruser/>
          <TagsSearch setSearchResultsPosts={setSearchResultsPosts} />
          {/* 3. PASS IT TO HEROPOSTS PAGE */}
          <HeroPostPage searchResultsPosts={searchResultsPosts} />
         </div>
      }/>


      {/* <Route path='add' element={<AddProduct/>}/> */}


    </Routes>
    </BrowserRouter>
  );
}

export default App;
