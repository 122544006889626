import React, { useEffect, useState } from 'react';
import { Link, useLocation  } from 'react-router-dom';
import axios from 'axios';

import { useNavigate, useParams } from 'react-router-dom';

const TagsSearch = ({ setSearchResultsPosts }) => {

  // State for search query and search results FOR USERS
//   const [searchResultsPostsCurrent, setSearchResultsPostsCurrent] = useState([]);

  // const {id} = useParams(); // for grabing ID from the URL
  const location = useLocation();
  const query = new URLSearchParams(location.search).get('query');

  const navigate = useNavigate();



 

  // Handle search submit
//   const handleSearch = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.get(`https://api.ngomongdong.com/searchusers?query=${searchQuery}`);
//       setSearchResults(response.data);
//     } catch (error) {
//       console.error("Error fetching search results", error);
//     }
//   };


  // useEffect(()=>{
  //   getUsersAndPostById();

  // }, [id]);

  useEffect(() => {
    if (query) {
        getPostByTags();
    }
  }, [query]);
  

  const getPostByTags = async() =>{
    try {
        const response = await axios.get(`https://api.ngomongdong.com/searchtags?query=${query}`);
        // setSearchResultsPostsCurrent(response.data.posts);

        // pass it to the parent NEWWWW (1. PASS THE POSTS DATA TO THE PARENT AS PROPS TO APP.JS)
        setSearchResultsPosts(response.data);
        // console.log(response.data)
    } catch (error) {
        // console.error("Error fetching search results", error);
    }
    
  }

  return (
    <div>
    </div>
  );
};

export default TagsSearch;
